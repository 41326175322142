import { useForm } from 'react-hook-form';
import { Card, Stack, Box, MenuItem, Typography, Fade } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import useAuth from '#/hooks/useAuth';
import { useUpdateProfile } from '#/api/userQueries';
import { fToDB } from '#/utils/formatTime';

const countries = [{ code: 'CH', label: 'global.switzerland' }];

const civilStatus = [
  {
    code: 'single',
  },
  {
    code: 'married',
  },
  {
    code: 'divorced',
  },
  {
    code: 'widowed',
  },
];

export default function RequiredBasicInfo() {
  const [visible, setVisible] = useState<boolean>(true);

  const { translate } = useLocales();
  const { user, refetch } = useAuth();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const defaultValues = useMemo(
    () => ({
      date_of_birth: user?.date_of_birth ?? '',
      civil_status: user?.civil_status ?? '',
      address: {
        street: user?.address?.street ?? '',
        house_number: user?.address?.house_number ?? '',
        zip_code: user?.address?.zip_code ?? '',
        city: user?.address?.city ?? '',
        country: user?.address?.country ?? 'CH',
      },
    }),
    // eslint-disable-next-line
    [user?.address, user?.civil_status]
  );

  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const fieldNames = {
    'address.street': 'route',
    'address.house_number': 'street_number',
    'address.zip_code': 'postal_code',
    'address.city': 'locality',
  };

  const isStreetError = Boolean(errors?.address?.street);

  useEffect(() => {
    if (user?.address || user?.civil_status) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [user?.address, user?.civil_status]);

  const handleUpdateUserInfo = async (data: any) => {
    const reqData = {
      ...data,
      date_of_birth: fToDB(data.date_of_birth),
    };
    await updateProfile(reqData).then(() => {
      refetch();
      setVisible(false);
    });
  };

  return (
    <Fade in={visible} timeout={500}>
      <Card sx={{ paddingX: 3, paddingY: 2, marginTop: 3 }}>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          {String(translate('global.requiredBasicInfoTitle'))}
        </Typography>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(handleUpdateUserInfo)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              sx={{
                width: '100%',
              }}
              spacing={2}
            >
              <Stack
                width={{
                  xs: '100%',
                  md: '50%',
                }}
              >
                <RHFDatePicker
                  name="date_of_birth"
                  label={String(translate('global.formLabels.date_of_birth'))}
                  disableFuture
                />
              </Stack>
              <Stack
                width={{
                  xs: '100%',
                  md: '50%',
                }}
              >
                <RHFSelect
                  name="civil_status"
                  label={String(translate('global.formLabels.civilStatus'))}
                >
                  {civilStatus.map((status) => (
                    <MenuItem key={status.code} value={status.code}>
                      {String(translate(`global.civilStatus.${status.code}`))}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              spacing={2}
            >
              <Stack
                sx={{
                  width: '100%',
                }}
              >
                <AutoAddressPicker
                  fieldNames={fieldNames}
                  streetError={isStreetError}
                  name="address.street"
                />
              </Stack>
              <RHFTextField
                name="address.house_number"
                label={String(translate('global.formLabels.houseNumber'))}
              />
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              spacing={2}
            >
              <RHFTextField
                name="address.zip_code"
                label={String(translate('global.formLabels.postalCode'))}
              />
              <RHFTextField
                name="address.city"
                label={String(translate('global.formLabels.city'))}
              />
            </Stack>
            <RHFSelect
              name="address.country"
              label={String(translate('global.formLabels.country'))}
              value={countries.find((country) => country.code === 'CH')?.code}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {String(translate(country.label))}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ marginTop: 2 }}
          >
            <Typography>{String(translate('global.save'))}</Typography>
          </LoadingButton>
        </FormProvider>
      </Card>
    </Fade>
  );
}
